<template>
  <div class="bac">
    <div class="main">
      <header>
        <div class="medium_head">
          <i></i>
          <p>报名详情</p>
        </div>
        <!--          这里是已选人数与排序下拉框start-->
        <div class="scrollHead">
          <p>已选人数：3/5</p>
          <button>已选</button>
          <select @change="getUserInf" v-model="getUserInfForm.sortRule">
            <option value="publishTime">时间</option>
            <option value="selectNum">成交量</option>
          </select>
        </div>
        <!--          这里是已选人数与排序下拉框end-->
      </header>
      <div>
          <div class="left">
            <div class="content">
              <div class="tableColumn">
                <div class="pic">
                  <img :src="this.Info.pic" alt="">
                </div>
                <div class="projectInfo ">
                  <div style="width: 528px;height: 128px">
                    <div class="data">
                      <div class="dataDay">
                        {{ this.Info.day }}
                      </div>
                      <div class="dataMonth">
                        {{ this.Info.yearAndMonth }}
                      </div>
                    </div>
                    <div class="projectName">
                      {{ this.Info.projectName }}
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="num">
                      <p>招募人数</p>
                      <p>3</p>
                    </div>
                    <div class="deadLine">
                      <p>剩余时间</p>
                      <p>{{ this.InfoNaNpxainingTime }}</p>
                    </div>
                    <div class="budget">
                      <p>项目预算</p>
                      <p>{{ this.Info.budget }}</p>
                    </div>
                  </div>
                 <button>查看详情</button>
                  <el-pagination
                      :page-size="1"
                      v-model:current-page="this.getInfForm.currentPage"
                      v-model:total="this.getInfForm.count"
                      layout="prev, pager, next"
                      @current-change="getInf()"
                      style="--el-pagination-hover-color: #ec414d;
                       --el-pagination-font-size: 18px;"
                  ></el-pagination>
                </div>
              </div>

            </div>
          </div>
          <div style="height: 574px">
            <el-scrollbar max-height="574px">
              <el-checkbox-group v-model="checkList" size="large" @change="userList()">
                <div class="applierList" v-for="(users, i) in userInfo">
                  <div class="userList">
<!--                    <el-checkbox :label="i" style="&#45;&#45;el-checkbox-checked-background-color: #ec414d;&#45;&#45;el-checkbox-checked-input-border-color: #ec414d;&#45;&#45;el-checkbox-checked-font-color: #ec414d; &#45;&#45;el-checkbox-input-border-color-hover: #ec414d;&#45;&#45;el-checkbox-checked-icon-color: #ec414d ;&#45;&#45;el-checkbox-input-height: 29px;&#45;&#45;el-checkbox-input-width: 29px;">-->
                      <div class="userInf">
                        <div class="result_card" style="float: left">
                          <div class="avatar">

                          </div>
                          <div class="userName">
                            <div>{{ users.username }}</div>
                            <div class="renzheng"></div>
                          </div><br>
                          <div class="area">
                            <div class="advantageField">
                              <div>擅长领域：</div>
                              <div>{{ users.areaGood }}</div>
                            </div>
                            <div class="address">
                              <div>地址：</div>
                              <div>{{ users.address }}</div>
                            </div>
                          </div>
                          <button class="selectButton">未选</button>
                          <button class="showDetail" @click="users.isShowDetail = isShowDetail(users.isShowDetail)">查看详情</button>

                          <div  class="popCard" style="width: 658px;height: 368px; z-index: 998; margin-top: 10px" v-show="users.isShowDetail === 1">
                            <el-card style="width: 658px;height: 368px;" v-show="users.isShowDetail === 1">
                              <div style="margin-bottom: 150px">
                                <el-descriptions title="申请详情" :column="2">
                                  <el-descriptions-item label="服务方式">{{ users.serverWay }}</el-descriptions-item>
                                  <el-descriptions-item label="服务部分">{{ users.serverPart }}</el-descriptions-item>
                                  <el-descriptions-item label="擅长描述">{{ users.advantage }}</el-descriptions-item>
                                </el-descriptions>
                              </div>

                              <el-button @click="decide(1, users.id, item.id)" type="success" style="margin-left: 70px">同意</el-button>
                              <el-button @click="decide(2, users.id, item.id)" type="danger" style="margin-left: 70px">拒绝</el-button>
                              <el-button type="info" style="margin-left: 70px">用户主页</el-button>

                            </el-card>
                          </div>
                        </div>
                      </div>
<!--                    </el-checkbox>-->
                  </div>
                </div>
              </el-checkbox-group>

            </el-scrollbar>
          </div>
        </div>


    </div>
  </div>
</template>


<script>
export default {
  name: "WinBidResult",
  data(){
    return{
      getInfForm:{
        currentPage: 1,
        pageSize: 1,
        count:'',
      },
      getUserInfForm:{
        pid: 1,
        sortRule:'selectNum',
      },
      decideForm:{
        pid:'',
        decide:'',
        applyUid:'',
      },

      Info:'',
      userInfo:'',

      projectIndex:0,
      checkList:[],
    }


  },
  mounted() {
    this.getInf();
  },
  methods:{
    getInf() {
      //this.ID.pid = parseInt(this.$route.query.projectId);

      this.$http({
        method: 'post',
        url: '/allApplyingProjects',
        data: this.getInfForm,
      }).then(res => {
        this.Info = res.data.projects[this.projectIndex];
        this.getInfForm.currentPage = res.data.currentPage;
        this.getInfForm.count = res.data.count;
        this.getUserInf();

      });
    },
    getUserInf() {
      this.getUserInfForm.pid = this.Info.id;
      this.$http({
        method: 'post',
        url: '/allApplyUsers',
        data: this.getUserInfForm,
      }).then(res => {
        this.userInfo = res.data;
      });
    },
    isShowDetail(index){
      if (index === 0){
        return 1;
      }else {
        return 0;
      }
    },
    //单个用户选择
    decide(y,applyUid,pid){
      this.decideForm.decide = y;
      this.decideForm.applyUid = applyUid;
      this.decideForm.pid = pid;

      this.$http({
        method: 'post',
        url: '/decideUserWinBid',
        data: this.decideForm,
      }).then(res => {
        this.$notify({
          title: '操作成功',
          message: '',
          type: 'success'
        });
        location.reload();
      });
    },
    //多个用户选择
    userList(){

    }
  }

}
</script>

<style scoped>

.bac{
  width: 1920px;
  padding-left: 298px;
  padding-top: 50px;
  background-color: #f5f5f5;
}

.main{
  float: left;
  width: 1514px;
  height: 818px;
  margin-left: 66px;
  margin-top: 72px;
  padding-top: 39px;
  background-color: #ffffff;
  border: 1px solid transparent;
}

.main .head{
  width: 1400px;
  height: 96px;
  margin-left: 30px;
}

.main .left{
  float: left;
  width: 528px;
  height: 618px;
  margin-left: 64px;
  margin-right: 228px;
  /*background-color: pink;*/
}

header{
  width: 100%;
  height: 75px;
  border: 1px solid transparent;
}

.medium_head{
  float: left;
  width: 755px;
  height: 36px;
  line-height: 36px;
  /*margin-left: 43px;*/
  margin-bottom: 24px;
  margin-left: 65px;

}

.medium_head i{
  display: block;
  float: left;
  width: 8px;
  height: 36px;
  margin-right: 27px;
  background-color: #ec414d;
}

.medium_head p {
  float: left;
  width: 102px;
  height: 36px;
  line-height: 36px;
  /*margin-top: 10px;*/
  font-size: 24px;
  color: #ec414d;
  font-weight: 700;
}

/*这里是已选人数与排序下拉框css-start*/
.scrollHead{
  float: left;
  width: 658px;
  height: 68px;
}

.scrollHead p{
  float: left;
  width: 158px;
  height: 24px;
  line-height: 24px;
  font-size: 24px;
  color: #212121;
  margin-left: 10px;
  margin-right: 202px;
  margin-top: 18px;
}

.scrollHead button{
  width: 115px;
  height: 48px;
  font-size: 24px;
  color: #ffffff;
  background-color: #ec414d;
  border-radius: 5px;
  margin-right: 56px;

}

.scrollHead select{
  width: 115px;
  height: 48px;
  font-size: 24px;
  color: #212121;
  border-radius: 5px;
  background-color: #e8e8e8;
  border: 1px solid transparent;
  text-align: center;
}

.scrollHead option{
  display: block;
  width: 115px;
  height: 16px;
  font-size: 16px;
  text-align: center;
  background-color: #ffffff;
  /*padding-top: 18px;*/
}

.head div{
  width: 152px;
  height: 96px;
  /*font-size: 28px;*/
  /*color: #ec414d;*/
}

.head div a{
  display: block;
  width: 152px;
  height: 94px;
  line-height: 96px;
  font-size: 28px;
  text-align: center;
  color: #ec414d;
}

.head div i{
  display: block;
  width: 152px;
  height: 2px;
  background-color: #ec414d;
}

.main .content{
  width: 1458px;
  height: 668px;
}


/*display部分css开始*/
.tableColumn{
  width: 528px;
  height: 474px;
  background-color: #ffffff;
  /*margin: 0 auto;*/
  /*border-bottom: 2px solid #faf0ee;*/
  /*padding-top: 50px;*/

}

.tableColumn .projectInfo{
  float: left;
  width: 528px;
  height: 128px;
}

.tableColumn .pic{
  /*float: left;*/
  width: 495px;
  height: 296px;
  /*background-color: #ec414d;*/
  margin-left: 32px;
  margin-bottom: 16px;
}

.tableColumn .pic img{
  width: 100%;
  height: 100%;
  transition: 0.5s all;
}

.tableColumn .pic img:hover{
  width: 105%;
  height: 105%;
}

.tableColumn .data{
  float: left;
  width: 125px;
  height: 125px;
  margin-left: 32px;
  /*margin-top: 105px;*/
  text-align: center;
  line-height: 125px;
  color: #ec414d;
  /*background-color: #1ea1e0;*/
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0,0,0,.2);
}

.tableColumn .dataDay{
  height: 40px;
  font-size: 40px;
  font-weight: 700;
  color: #ec414d;
  /*margin-bottom:15px;*/
  margin-top: -10px;
}

.tableColumn .dataMonth{
  height: 21px;
  font-size: 21px;
  color: #ec414d;
}

.tableColumn .projectName{
  float: left;
  height: 36px;
  width: 330px;
  line-height: 36px;
  font-size: 36px;
  color: #212121;
  font-weight: 500;
  margin-left: 30px;
  margin-top: 32px;
}

.tableColumn .recruit{
  float: left;
  margin-left: 96px;
  width: 90px;
  height: 32px;
  font-size: 16px;
  text-align: center;
  line-height: 32px;
  background-color: #ff6460;
  color: #ffffff;
  border-radius: 5px;
  margin-top: 40px;
}

.tableColumn .bottom{
  /*float: left;*/
  width: 528px;
  height: 69px;
  margin-top:45px;
}

.tableColumn .num{
  float: left;
  width: 56px;
  height: 75px;
  /*margin-left: -540px;*/
  /*margin-top: 100px;*/
  margin-left: 186px;
  /*background-color: #1ea1e0;*/
  text-align: center;
}

.tableColumn .num p:nth-child(1){
  width: 56px;
  font-size: 14px;
  color: #9e9e9e;
  margin-bottom: 22px;
}

.tableColumn .num p:nth-child(2){
  width: 56px;
  font-size: 16px;
  color: #212121;
  /*margin-bottom: 27px;*/
}

.tableColumn .deadLine{
  float: left;
  width: 56px;
  height: 75px;
  /*margin-left: -540px;*/
  /*margin-top: 100px;*/
  margin-left: 72px;
  text-align: center;
  /*background-color: #1ea1e0;*/

}

.tableColumn .deadLine p:nth-child(1){
  width: 56px;
  font-size: 14px;
  color: #9e9e9e;
  margin-bottom: 22px;
}

.tableColumn .deadLine p:nth-child(2){
  width: 56px;
  font-size: 16px;
  color: #212121;
  /*margin-bottom: 27px;*/
}

.tableColumn .budget{
  float: left;
  width: 56px;
  height: 75px;
  /*margin-left: -540px;*/
  /*margin-top: 100px;*/
  margin-left: 72px;
  text-align: center;
  /*margin-bottom: 10px;*/
  /*background-color: #1ea1e0;*/

}

.tableColumn .budget p:nth-child(1){
  width: 56px;
  font-size: 14px;
  color: #9e9e9e;
  margin-bottom: 22px;
}

.tableColumn .budget p:nth-child(2){
  width: 56px;
  font-size: 16px;
  color: #212121;
  /*margin-bottom: 27px;*/
  /*margin-bottom: 20px;*/
}

.tableColumn button{
  width: 126px;
  height: 45px;
  margin-top: 32px;
  margin-left: 402px;
  color: #fef6f6;
  background-color: #212121;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
  background-image: linear-gradient(115deg,transparent 50%,#ec414d 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  transition: all .7s;
}

.tableColumn button:hover{
  background-size: 300%;
}

/*dispaly部分的css代码结束*/

.content .tidai{
  width: 1458px;
  height: 436px;
  background-color: pink;
}

.content .comment{
  width: 1165px;
  height: 86px;
  margin-left: 96px;
  margin-bottom: 24px;
}

.content .comment .tx{
  float: left;
  width: 86px;
  height: 86px;
  border-radius: 50%;
  /*background-color: pink;*/
  margin-right: 24px;
  background-image: url("../../../../images/tx.png");
  background-size: 100% 100%;
}

.content .comment .info{
  float: left;
  width: 1055px;
  height: 86px;
}

.content .info .info_top{
  width: 1055px;
  height: 22px;
  margin-bottom: 17px;
}

.content .info .info_top .projectName{
  float: left;
  font-size: 18px;
  color: #212121;
  margin-right: 24px;
  font-weight: 500;
  /*margin-right: 200px;*/
}

.content .info .info_top .years{
  float: left;
  font-size: 18px;
  color: #676364;
  margin-right: 12px;
}

.content .info .info_top .time{
  float: left;
  font-size: 18px;
  color: #676364;
}

.content .info .info_bottom{
  width: 1055px;
  height: 64px;
}

.content .info .info_bottom input{
  width: 1055px;
  height: 48px;
  padding-left: 30px;
  color: #676364;
  font-size: 18px;
  border: 1px solid #676364;
}

.main .applierList{
  float: left;
  width: 658px;
  /*height: 368px;*/
  /*background-color: pink;*/
}


.main .applierList .userList{
  width: 658px;
  height: 125px;
  /*background-color: purple;*/
}

.userInf{
  /*float: left;*/
  width: 658px;
  height: 126px;
  margin-bottom: 20px;
}

.userInf .result_card{
  position: relative;
  width: 658px;
  height: 122px;
  margin-bottom: 14px;
  margin-right: 65px;
  background-color: #f8f8f8;
  padding-top: 24px;

}


.userInf .result_card .showDetail,.selectButton{
  position: absolute;
  right: 34px;
  bottom: 40px;
  width: 95px;
  height: 35px;
  /*margin-top: 15px;*/
  color: #fef6f6;
  background-color: #ec414d;
  /*border: 1px solid transparent;*/
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  /*background-image: linear-gradient(115deg,transparent 50%,#212121 50%);*/

}

.userInf .result_card .selectButton{
  right: 154px;
  background-color: grey;

}

.userInf .result_card .avatar{
  float: left;
  width: 65px;
  height: 65px;
  margin-left: 50px;
  margin-right: 20px;
  border-radius: 50%;
  background-image: url("../../../../images/tx.png");
  background-size: 100% 100%;
  /*background-color: #1ea1e0;*/
  /*margin-top: 19px;*/
}

.userInf .result_card .projectName{
  float: left;
  /*float: left;*/
  width: 242px;
  height: 16px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #212121;
  margin-top: 8px;
  margin-right: 125px;
}

.userInf .result_card .projectName div{
  float: left;
}

.userInf .result_card .area{
  float: left;
  height: 12px;
  width: 280px;
  font-size: 12px;
  color: #c5c5c5;
  line-height: 12px;
  /*margin-top: 13px;*/
}

.userInf .result_card .area div{
  float: left;
}

.userInf .result_card .area .address{
  margin-left: 6px;
  margin-right: 6px;
}

.userInf .result_card .userName{
  height: 22px;
  margin-bottom: 20px;
}

.userInf .result_card .userName div{
  float: left;
}

.userInf .result_card .renzheng{
  float: left;
  width: 20px;
  height: 22px;
  /*background-color: #ec414d;*/
  margin-left: 10px;
  margin-right: 40px;
  margin-bottom: 20px;
  background-image: url("../../../../images/认证.png");
  background-size: 100% 100%;
}

.main .applierList .userList .applyInf .line i{
  display: block;
  float: left;
  width: 96px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  color: #212121;
  margin-right: 18px;
  /*margin-bottom: 32px;*/
}

.main .applierList .userList .applyInf .line input{
  width: 450px;
  height: 50px;
  font-size: 24px;
  color: #212121;
  padding-left: 12px;
  border-radius: 5px;
}

/*.main .applierList .userList .applyInf button{
  width: 140px;
  height: 52px;
  color: #ffffff;
  font-size: 24px;
  line-height: 52px;
  text-align: center;
  background-color: #e93d50;
  border-radius: 5px;
  margin-right: 54px;
}

.main .applierList .userList .applyInf button:nth-of-type(1){
  margin-left: 20px;
}

.main .applierList .userList .applyInf button:nth-of-type(2){
  !*margin-left: 20px;*!
  background-color: #171717;
}

.main .applierList .userList .applyInf button:nth-of-type(3){
  !*margin-left: 20px;*!
  background-color: #171717;
}*/

.popCard{
  position: absolute;
  /*display: none;*/
  top: 122px;
  background-color: #ffffff;
}



</style>